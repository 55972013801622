<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入房间名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.ownerId"
              placeholder="请输入用户id"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">

      </div>
    </div>
    <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
      <el-table-column prop="id" label="房间id"></el-table-column>
      <el-table-column prop="name" label="房间名称"></el-table-column>
      <el-table-column prop="notice" label="房间公告" show-overflow-tooltip></el-table-column>
      <el-table-column prop="balance" label="封面">
        <template slot-scope="scope">
          <img :width="100" :src="scope.row.thumbnail" class="icon" />
        </template>
      </el-table-column>
      <el-table-column prop="userCount" label="在线人员"></el-table-column>
      <el-table-column prop="totalFinishTask" label="累计完成任务"></el-table-column>
      <el-table-column prop="taskPercent" label="任务完成率">
        <template slot-scope="scope">
          <span>{{(scope.row.taskRate || 0)*100}}%</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="liveTime" label="开播时长"></el-table-column> -->
      <el-table-column prop="totalLiveTime" label="累计开播时长"></el-table-column>
      <el-table-column prop="lastLiveTime" label="最近开播"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.updateTime | formatDate}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="290">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >置顶</el-button
          >
          <el-button size="small" type="warning" @click="toEdit(scope.row)"
            >未审核</el-button
          >
          <el-button size="small" type="info" @click="goInfo(scope.row)"
            >详情</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" title="编辑" :visible.sync="addVisible" width="650px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号码" prop="giftName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.giftName"
          ></el-input>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {},
      searchForm: {
        name:undefined,
        ownerId:undefined,
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{pagination},
  created() {
    this.getTableList();
  },
  methods: {
    goInfo(row){
      this.$router.push({name:'',query:{id:row.id}})
    },
    toEdit(data) {
      this.ruleForm = {...data}
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.giftSave({...this.ruleForm});
          if (res) {
            this.$message.success("修改成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    changeSort(val) {
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? "ascend" : "descend";
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
      }
      this.getTableList(true);
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.roomList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.giftDelete([row.id])
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.icon{
  width: 60px;
}
</style>
